/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getInvoice = /* GraphQL */ `query GetInvoice($id: ID!) {
  getInvoice(id: $id) {
    id
    owner
    userId
    status
    subscriptionId
    title
    net
    currency
    discount
    tax
    subtotal
    total
    amountPaid
    amountRefunded
    paymentError
    createdAt
    updatedAt
    paidAt
    refundedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetInvoiceQueryVariables,
  APITypes.GetInvoiceQuery
>;
export const listInvoices = /* GraphQL */ `query ListInvoices(
  $id: ID
  $filter: ModelInvoiceFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listInvoices(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      owner
      userId
      status
      subscriptionId
      title
      net
      currency
      discount
      tax
      subtotal
      total
      amountPaid
      amountRefunded
      paymentError
      createdAt
      updatedAt
      paidAt
      refundedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListInvoicesQueryVariables,
  APITypes.ListInvoicesQuery
>;
export const getOrder = /* GraphQL */ `query GetOrder($id: ID!) {
  getOrder(id: $id) {
    id
    owner
    userId
    status
    title
    shippingCarrier
    trackingId
    trackingURL
    trackingEvents {
      createdAt
      title
      description
      __typename
    }
    lineItems {
      productId
      parentProductId
      name
      quantity
      __typename
    }
    createdAt
    updatedAt
    submittedAt
    shippedAt
    completedAt
    canceledAt
    failedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetOrderQueryVariables, APITypes.GetOrderQuery>;
export const listOrders = /* GraphQL */ `query ListOrders(
  $id: ID
  $filter: ModelOrderFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listOrders(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      owner
      userId
      status
      title
      shippingCarrier
      trackingId
      trackingURL
      createdAt
      updatedAt
      submittedAt
      shippedAt
      completedAt
      canceledAt
      failedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOrdersQueryVariables,
  APITypes.ListOrdersQuery
>;
export const getPreliminaryPlan = /* GraphQL */ `query GetPreliminaryPlan($id: ID!) {
  getPreliminaryPlan(id: $id) {
    id
    createdAt
    updatedAt
    expiresAt
    email
    member {
      anonymousId
      firstName
      lastName
      name
      email
      affiliations
      age
      sex
      diets
      sports
      goals
      medicalConditions
      nutrientDeficiencies
      __typename
    }
    products {
      id
      slug
      name
      category
      features
      shortDescription
      longDescription
      servingCount
      __typename
    }
    preferences {
      pregnancyStage
      gummyFlavor
      __typename
    }
    builds {
      buildId
      startAt
      endAt
      initBy
      error
      errors
      typeformEventPayloadArn
      questionnaireDataPayloadArn
      userDataPayloadArn
      assessmentRecommendationPayloadArn
      ruleRecommendationPayloadArn
      dosageRecommendationPayloadArn
      productRecommendationPayloadArn
      __typename
    }
    affiliationCode
    referralCode
    status
    error
    errorMessages
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPreliminaryPlanQueryVariables,
  APITypes.GetPreliminaryPlanQuery
>;
export const getPurchasableSubscription = /* GraphQL */ `query GetPurchasableSubscription($id: ID!) {
  getPurchasableSubscription(id: $id) {
    id
    eloId
    slug
    title
    phases {
      id
      eloId
      slug
      pregnancyStage
      gender
      durationDays
      startAt
      endAt
      __typename
    }
    metaData
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPurchasableSubscriptionQueryVariables,
  APITypes.GetPurchasableSubscriptionQuery
>;
export const listPurchasableSubscriptions = /* GraphQL */ `query ListPurchasableSubscriptions(
  $id: ID
  $filter: ModelPurchasableSubscriptionFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listPurchasableSubscriptions(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      eloId
      slug
      title
      metaData
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPurchasableSubscriptionsQueryVariables,
  APITypes.ListPurchasableSubscriptionsQuery
>;
export const usersByOwnerAndCreatedAt = /* GraphQL */ `query UsersByOwnerAndCreatedAt(
  $owner: String!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  usersByOwnerAndCreatedAt(
    owner: $owner
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      owner
      email
      eloId
      firstName
      lastName
      createdAt
      updatedAt
      deletedAt
      deleteRequestedAt
      deleteScheduledAfter
      deleteReason
      deleteLog
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UsersByOwnerAndCreatedAtQueryVariables,
  APITypes.UsersByOwnerAndCreatedAtQuery
>;
export const getUserSubscription = /* GraphQL */ `query GetUserSubscription($id: ID!) {
  getUserSubscription(id: $id) {
    id
    owner
    userId
    status
    title
    cancellationReason
    eloId
    purchasableSubscriptionId
    purchasableSubscription {
      id
      eloId
      slug
      title
      metaData
      createdAt
      updatedAt
      __typename
    }
    purchasedSubscription {
      id
      eloId
      slug
      title
      metaData
      createdAt
      updatedAt
      __typename
    }
    preferences {
      gummyFlavor
      __typename
    }
    invoices {
      nextToken
      __typename
    }
    committedInvoices {
      orderId
      status
      reason
      dueAt
      periodStartAt
      periodEndAt
      title
      currency
      shipping
      discount
      tax
      subtotal
      total
      __typename
    }
    upcomingInvoices {
      orderId
      status
      reason
      dueAt
      periodStartAt
      periodEndAt
      title
      currency
      shipping
      discount
      tax
      subtotal
      total
      __typename
    }
    createdAt
    updatedAt
    startAt
    endAt
    periodStartAt
    periodEndAt
    committedUntil
    pauseRequestedAt
    pausedAt
    pausedUntil
    cancellationRequestedAt
    affiliationCode
    referralCode
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserSubscriptionQueryVariables,
  APITypes.GetUserSubscriptionQuery
>;
export const listUserSubscriptions = /* GraphQL */ `query ListUserSubscriptions(
  $id: ID
  $filter: ModelUserSubscriptionFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listUserSubscriptions(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      owner
      userId
      status
      title
      cancellationReason
      eloId
      purchasableSubscriptionId
      createdAt
      updatedAt
      startAt
      endAt
      periodStartAt
      periodEndAt
      committedUntil
      pauseRequestedAt
      pausedAt
      pausedUntil
      cancellationRequestedAt
      affiliationCode
      referralCode
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserSubscriptionsQueryVariables,
  APITypes.ListUserSubscriptionsQuery
>;
export const getLineItems = /* GraphQL */ `query GetLineItems($input: GetLineItemsInput) {
  getLineItems(input: $input) {
    productId
    productSlug
    product {
      id
      slug
      name
      category
      features
      shortDescription
      longDescription
      servingCount
      __typename
    }
    purchasableSubscriptionId
    purchasableSubscriptionSlug
    purchasableSubscription {
      id
      eloId
      slug
      title
      metaData
      createdAt
      updatedAt
      __typename
    }
    quantity
    priceId
    unitPrice
    totalPrice
    discount
    subTotalPrice
    title
    currency
    flavors
    referrerName
    referralCode
    affiliation
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetLineItemsQueryVariables,
  APITypes.GetLineItemsQuery
>;
export const getCheckout = /* GraphQL */ `query GetCheckout($sessionId: ID!) {
  getCheckout(sessionId: $sessionId) {
    checkoutUrl
    expiresAt
    orderId
    name
    email
    shippingAddress {
      name
      company
      street
      street2
      city
      state
      zip
      country
      __typename
    }
    billingAddress {
      name
      company
      street
      street2
      city
      state
      zip
      country
      __typename
    }
    paymentMethodType
    paymentMethodDescription
    lineItems {
      type
      title
      id
      priceId
      sku
      imageUrl
      description
      quantity
      unitPrice
      trialUnitPrice
      currency
      referralCode
      __typename
    }
    totalPrice {
      currency
      discount
      shipping
      tax
      total
      __typename
    }
    appStoreDownloadUrl
    fail {
      errorCode
      errorMessage
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCheckoutQueryVariables,
  APITypes.GetCheckoutQuery
>;
export const me = /* GraphQL */ `query Me {
  me {
    id
    owner
    email
    eloId
    firstName
    lastName
    preferences {
      pregnancyStage
      gummyFlavor
      __typename
    }
    paymentMethod {
      type
      title
      last4
      partnerId
      partnerPaymentMethodId
      expirationYear
      expirationMonth
      __typename
    }
    billingAddress {
      name
      company
      street
      street2
      city
      state
      zip
      country
      __typename
    }
    shippingAddress {
      name
      company
      street
      street2
      city
      state
      zip
      country
      __typename
    }
    invoices {
      nextToken
      __typename
    }
    orders {
      nextToken
      __typename
    }
    subscriptions {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    deletedAt
    deleteRequestedAt
    deleteScheduledAfter
    deleteReason
    deleteLog
    __typename
  }
}
` as GeneratedQuery<APITypes.MeQueryVariables, APITypes.MeQuery>;
export const userConfiguration = /* GraphQL */ `query UserConfiguration {
  userConfiguration {
    allPurchasableSubscriptions {
      id
      eloId
      slug
      title
      metaData
      createdAt
      updatedAt
      __typename
    }
    purchasableSubscriptions {
      id
      eloId
      slug
      title
      metaData
      createdAt
      updatedAt
      __typename
    }
    gummyFlavors
    pregnancyStages
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserConfigurationQueryVariables,
  APITypes.UserConfigurationQuery
>;
export const paymentGatewayCredentials = /* GraphQL */ `query PaymentGatewayCredentials($paymentGateway: PaymentGateway!) {
  paymentGatewayCredentials(paymentGateway: $paymentGateway) {
    customerId
    ephemeralKeySecret
    setupIntentClientSecret
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PaymentGatewayCredentialsQueryVariables,
  APITypes.PaymentGatewayCredentialsQuery
>;
export const zendeskToken = /* GraphQL */ `query ZendeskToken {
  zendeskToken {
    token
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ZendeskTokenQueryVariables,
  APITypes.ZendeskTokenQuery
>;
