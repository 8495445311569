/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type StartCheckoutInput = {
  lineItems?: Array< LineItemInput > | null,
  customerEmail?: string | null,
  anonymousId?: string | null,
  referenceId?: string | null,
  preliminaryPlanId?: string | null,
  targeting?: TargetingInput | null,
  promotionCode?: string | null,
  cancelUrl?: string | null,
  metaData?: string | null,
  directPurchase?: boolean | null,
  referralCode?: string | null,
  affiliation?: string | null,
};

export type LineItemInput = {
  productId?: string | null,
  purchasableSubscriptionId?: string | null,
  quantity: number,
  priceId: string,
  flavor?: GummyFlavor | null,
};

export enum GummyFlavor {
  blackcurrant = "blackcurrant",
  strawberry = "strawberry",
  greenApple = "greenApple",
  watermelon = "watermelon",
  peach = "peach",
  cola = "cola",
  pineapple = "pineapple",
  orange = "orange",
  bloodOrange = "bloodOrange",
  eloOriginal = "eloOriginal",
  yuzu = "yuzu",
  apple = "apple",
  grapefruit = "grapefruit",
  bloodOrangeYuzu = "bloodOrangeYuzu",
  mixedBerry = "mixedBerry",
  raspberry = "raspberry",
}


export type TargetingInput = {
  utmCampaign?: string | null,
  utmSource?: string | null,
  utmMedium?: string | null,
  utmContent?: string | null,
  utmTerm?: string | null,
};

export type CheckoutDetails = {
  __typename: "CheckoutDetails",
  checkoutUrl?: string | null,
  expiresAt?: string | null,
  orderId?: string | null,
  name?: string | null,
  email?: string | null,
  shippingAddress?: Address | null,
  billingAddress?: Address | null,
  paymentMethodType?: string | null,
  paymentMethodDescription?: string | null,
  lineItems?:  Array<TransactionLineItem | null > | null,
  totalPrice?: PriceDetails | null,
  appStoreDownloadUrl?: string | null,
  fail?: CheckoutStartFailure | null,
};

export type Address = {
  __typename: "Address",
  name?: string | null,
  company?: string | null,
  street?: string | null,
  street2?: string | null,
  city?: string | null,
  state?: string | null,
  zip?: string | null,
  country?: string | null,
};

export type TransactionLineItem = {
  __typename: "TransactionLineItem",
  type: TransactionLineItemType,
  title: string,
  id: string,
  priceId?: string | null,
  sku?: string | null,
  imageUrl?: string | null,
  description?: string | null,
  quantity: number,
  unitPrice: number,
  trialUnitPrice?: number | null,
  currency: Currency,
  referralCode?: string | null,
};

export enum TransactionLineItemType {
  product = "product",
  discount = "discount",
  duty = "duty",
  shipping = "shipping",
  tax = "tax",
  total = "total",
}


export enum Currency {
  USD = "USD",
  EUR = "EUR",
}


export type PriceDetails = {
  __typename: "PriceDetails",
  currency?: Currency | null,
  discount?: number | null,
  shipping?: number | null,
  tax?: number | null,
  total?: number | null,
};

export type CheckoutStartFailure = {
  __typename: "CheckoutStartFailure",
  errorCode?: CheckoutStartError | null,
  errorMessage?: string | null,
};

export enum CheckoutStartError {
  DUPLICATE_SUBSCRIPTION = "DUPLICATE_SUBSCRIPTION",
  OTHER = "OTHER",
}


export type UpdateUserInput = {
  firstName?: string | null,
  lastName?: string | null,
  preferences?: UserPreferencesInput | null,
  shippingAddress?: AddressInput | null,
  paymentMethod?: PaymentMethodInput | null,
};

export type UserPreferencesInput = {
  pregnancyStage?: PregnancyStage | null,
  gummyFlavor?: GummyFlavor | null,
};

export enum PregnancyStage {
  preconception = "preconception",
  pregnant = "pregnant",
  pregnantTrimester1 = "pregnantTrimester1",
  pregnantTrimester2 = "pregnantTrimester2",
  pregnantTrimester3 = "pregnantTrimester3",
  lactating = "lactating",
  postpartum = "postpartum",
  lostPregnancy = "lostPregnancy",
  reproductiveHealth = "reproductiveHealth",
  maleReproductiveHealth = "maleReproductiveHealth",
}


export type AddressInput = {
  name?: string | null,
  company?: string | null,
  street?: string | null,
  street2?: string | null,
  city?: string | null,
  state?: string | null,
  zip?: string | null,
  country?: string | null,
};

export type PaymentMethodInput = {
  type?: PaymentMethodType | null,
  title?: string | null,
  last4?: string | null,
  paymentMethodId?: string | null,
  expirationYear?: string | null,
  expirationMonth?: string | null,
};

export enum PaymentMethodType {
  applePay = "applePay",
  googlePay = "googlePay",
  creditCard = "creditCard",
}


export type UpdateUserResult = {
  __typename: "UpdateUserResult",
  firstName?: string | null,
  lastName?: string | null,
  preferences?: UserPreferences | null,
  shippingAddress?: Address | null,
};

export type UserPreferences = {
  __typename: "UserPreferences",
  pregnancyStage?: PregnancyStage | null,
  gummyFlavor?: GummyFlavor | null,
};

export type CancelSubscriptionInput = {
  subscriptionId: string,
  reason?: string | null,
};

export type CancelSubscriptionResult = {
  __typename: "CancelSubscriptionResult",
  userSubscription?: UserSubscription | null,
};

export type UserSubscription = {
  __typename: "UserSubscription",
  id: string,
  owner?: string | null,
  userId?: string | null,
  status: SubscriptionStatus,
  title?: string | null,
  cancellationReason?: string | null,
  eloId?: string | null,
  purchasableSubscriptionId: string,
  purchasableSubscription?: PurchasableSubscription | null,
  purchasedSubscription: PurchasableSubscription,
  preferences?: UserSubscriptionPreferences | null,
  invoices?: ModelInvoiceConnection | null,
  committedInvoices?:  Array<UpcomingInvoice | null > | null,
  upcomingInvoices?:  Array<UpcomingInvoice | null > | null,
  createdAt: string,
  updatedAt?: string | null,
  startAt?: string | null,
  endAt?: string | null,
  periodStartAt?: string | null,
  periodEndAt?: string | null,
  committedUntil?: string | null,
  pauseRequestedAt?: string | null,
  pausedAt?: string | null,
  pausedUntil?: string | null,
  cancellationRequestedAt?: string | null,
  affiliationCode?: string | null,
  referralCode?: string | null,
};

export enum SubscriptionStatus {
  active = "active",
  paused = "paused",
  canceled = "canceled",
  unpaid = "unpaid",
}


export type PurchasableSubscription = {
  __typename: "PurchasableSubscription",
  id: string,
  eloId?: string | null,
  slug: string,
  title: string,
  phases:  Array<SubscriptionPhase >,
  metaData?: string | null,
  createdAt: string,
  updatedAt?: string | null,
};

export type SubscriptionPhase = {
  __typename: "SubscriptionPhase",
  id: string,
  eloId: string,
  slug: string,
  product: Product,
  pregnancyStage?: PregnancyStage | null,
  gender?: Gender | null,
  durationDays?: number | null,
  startAt?: string | null,
  endAt?: string | null,
};

export type Product = {
  __typename: "Product",
  id?: string | null,
  slug?: string | null,
  name?: string | null,
  category?: string | null,
  features?: Array< string | null > | null,
  pricing?:  Array<ProductPrice | null > | null,
  shortDescription?: string | null,
  longDescription?: string | null,
  serving?: Serving | null,
  servingCount?: number | null,
  products?:  Array<Product | null > | null,
  ingredients?:  Array<Ingredient | null > | null,
  assets?:  Array<WebAsset | null > | null,
};

export type ProductPrice = {
  __typename: "ProductPrice",
  id?: string | null,
  type?: string | null,
  currency?: Currency | null,
  billingScheme?: string | null,
  unitAmount?: number | null,
  recurring?: RecurringPricing | null,
};

export type RecurringPricing = {
  __typename: "RecurringPricing",
  aggregateUsage?: string | null,
  interval?: string | null,
  intervalCount?: number | null,
  usageType?: string | null,
};

export type Serving = {
  __typename: "Serving",
  unit?: string | null,
  quantity?: number | null,
};

export type Ingredient = {
  __typename: "Ingredient",
  id: string,
  slug: string,
  name: string,
  category?: string | null,
  shortDescription?: string | null,
  longDescription?: string | null,
  dose?: Dosage | null,
  assets?:  Array<WebAsset | null > | null,
};

export type Dosage = {
  __typename: "Dosage",
  quantity: number,
  unit: string,
};

export type WebAsset = {
  __typename: "WebAsset",
  createdAt?: string | null,
  contentType?: string | null,
  title?: string | null,
  description?: string | null,
  tags?: Array< string | null > | null,
  assetKey?: string | null,
  assetUrl?: string | null,
  thumbnailKey?: string | null,
  thumbnailUrl?: string | null,
  cmsKey?: string | null,
  cmsId?: string | null,
};

export enum Gender {
  female = "female",
  male = "male",
  other = "other",
}


export type UserSubscriptionPreferences = {
  __typename: "UserSubscriptionPreferences",
  gummyFlavor?: GummyFlavor | null,
};

export type ModelInvoiceConnection = {
  __typename: "ModelInvoiceConnection",
  items:  Array<Invoice | null >,
  nextToken?: string | null,
};

export type Invoice = {
  __typename: "Invoice",
  id: string,
  owner?: string | null,
  userId?: string | null,
  status: InvoiceStatus,
  subscriptionId: string,
  title?: string | null,
  net?: number | null,
  currency?: Currency | null,
  discount?: number | null,
  tax?: number | null,
  subtotal?: number | null,
  total?: number | null,
  amountPaid?: number | null,
  amountRefunded?: number | null,
  paymentError?: string | null,
  createdAt: string,
  updatedAt?: string | null,
  paidAt?: string | null,
  refundedAt?: string | null,
};

export enum InvoiceStatus {
  draft = "draft",
  open = "open",
  paid = "paid",
  refunded = "refunded",
}


export type UpcomingInvoice = {
  __typename: "UpcomingInvoice",
  orderId?: string | null,
  status: InvoiceStatus,
  reason: InvoiceReason,
  dueAt: string,
  periodStartAt?: string | null,
  periodEndAt?: string | null,
  title?: string | null,
  currency: Currency,
  shipping?: number | null,
  discount?: number | null,
  tax?: number | null,
  subtotal?: number | null,
  total: number,
};

export enum InvoiceReason {
  manual = "manual",
  subscriptionCreate = "subscriptionCreate",
  subscriptionCycle = "subscriptionCycle",
  subscriptionUpdate = "subscriptionUpdate",
  upcoming = "upcoming",
  other = "other",
}


export type UpdateSubscriptionInput = {
  subscriptionId: string,
  preferences?: UserSubscriptionPreferencesInput | null,
};

export type UserSubscriptionPreferencesInput = {
  gummyFlavor?: GummyFlavor | null,
};

export type UpdateSubscriptionResult = {
  __typename: "UpdateSubscriptionResult",
  userSubscription?: UserSubscription | null,
};

export type DeleteUserInput = {
  reason?: string | null,
};

export type DeleteUserResult = {
  __typename: "DeleteUserResult",
  deleteScheduledAfter?: string | null,
};

export type ReferralLinkDetails = {
  __typename: "ReferralLinkDetails",
  referralCode: string,
  url: string,
};

export type ModelInvoiceFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  status?: ModelInvoiceStatusInput | null,
  subscriptionId?: ModelIDInput | null,
  title?: ModelStringInput | null,
  net?: ModelFloatInput | null,
  currency?: ModelCurrencyInput | null,
  discount?: ModelFloatInput | null,
  tax?: ModelFloatInput | null,
  subtotal?: ModelFloatInput | null,
  total?: ModelFloatInput | null,
  amountPaid?: ModelFloatInput | null,
  amountRefunded?: ModelFloatInput | null,
  paymentError?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  paidAt?: ModelStringInput | null,
  refundedAt?: ModelStringInput | null,
  and?: Array< ModelInvoiceFilterInput | null > | null,
  or?: Array< ModelInvoiceFilterInput | null > | null,
  not?: ModelInvoiceFilterInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelInvoiceStatusInput = {
  eq?: InvoiceStatus | null,
  ne?: InvoiceStatus | null,
};

export type ModelFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelCurrencyInput = {
  eq?: Currency | null,
  ne?: Currency | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type Order = {
  __typename: "Order",
  id: string,
  owner?: string | null,
  userId?: string | null,
  status: OrderStatus,
  title?: string | null,
  shippingCarrier?: string | null,
  trackingId?: string | null,
  trackingURL?: string | null,
  trackingEvents?:  Array<ShipmentEvent > | null,
  lineItems?:  Array<OrderLineItem > | null,
  createdAt: string,
  updatedAt?: string | null,
  submittedAt?: string | null,
  shippedAt?: string | null,
  completedAt?: string | null,
  canceledAt?: string | null,
  failedAt?: string | null,
};

export enum OrderStatus {
  new = "new",
  submitted = "submitted",
  shipping = "shipping",
  completed = "completed",
  canceled = "canceled",
  failed = "failed",
}


export type ShipmentEvent = {
  __typename: "ShipmentEvent",
  createdAt: string,
  title?: string | null,
  description?: string | null,
  address?: Address | null,
};

export type OrderLineItem = {
  __typename: "OrderLineItem",
  productId?: string | null,
  product?: Product | null,
  parentProductId?: string | null,
  name?: string | null,
  quantity?: number | null,
};

export type ModelOrderFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  status?: ModelOrderStatusInput | null,
  title?: ModelStringInput | null,
  shippingCarrier?: ModelStringInput | null,
  trackingId?: ModelStringInput | null,
  trackingURL?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  submittedAt?: ModelStringInput | null,
  shippedAt?: ModelStringInput | null,
  completedAt?: ModelStringInput | null,
  canceledAt?: ModelStringInput | null,
  failedAt?: ModelStringInput | null,
  and?: Array< ModelOrderFilterInput | null > | null,
  or?: Array< ModelOrderFilterInput | null > | null,
  not?: ModelOrderFilterInput | null,
};

export type ModelOrderStatusInput = {
  eq?: OrderStatus | null,
  ne?: OrderStatus | null,
};

export type ModelOrderConnection = {
  __typename: "ModelOrderConnection",
  items:  Array<Order | null >,
  nextToken?: string | null,
};

export type PreliminaryPlan = {
  __typename: "PreliminaryPlan",
  id: string,
  createdAt: string,
  updatedAt?: string | null,
  expiresAt?: string | null,
  email: string,
  member: PreliminaryPlanMember,
  products?:  Array<PreliminaryPlanProduct | null > | null,
  preferences?: PreliminaryPlanPreferences | null,
  builds?:  Array<PlanBuildLog | null > | null,
  affiliationCode?: string | null,
  referralCode?: string | null,
  status: PreliminaryPlanStatus,
  error?: boolean | null,
  errorMessages?: Array< string | null > | null,
};

export type PreliminaryPlanMember = {
  __typename: "PreliminaryPlanMember",
  anonymousId?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  name?: string | null,
  email?: string | null,
  affiliations?: Array< string | null > | null,
  age?: number | null,
  sex?: string | null,
  diets?: Array< string | null > | null,
  sports?: Array< string | null > | null,
  goals?: Array< string | null > | null,
  medicalConditions?: Array< string | null > | null,
  nutrientDeficiencies?: Array< string | null > | null,
  targeting?: PreliminaryPlanTargeting | null,
};

export type PreliminaryPlanTargeting = {
  __typename: "PreliminaryPlanTargeting",
  utmCampaign?: string | null,
  utmSource?: string | null,
  utmMedium?: string | null,
  utmContent?: string | null,
  utmTerm?: string | null,
};

export type PreliminaryPlanProduct = {
  __typename: "PreliminaryPlanProduct",
  id?: string | null,
  slug?: string | null,
  name?: string | null,
  category?: string | null,
  features?: Array< string | null > | null,
  pricing?:  Array<PreliminaryPlanProductPrice | null > | null,
  shortDescription?: string | null,
  longDescription?: string | null,
  serving?: PreliminaryPlanServing | null,
  servingCount?: number | null,
  products?:  Array<PreliminaryPlanProduct | null > | null,
  ingredients?:  Array<PreliminaryPlanIngredient | null > | null,
  assessments?:  Array<PreliminaryPlanAssessment | null > | null,
};

export type PreliminaryPlanProductPrice = {
  __typename: "PreliminaryPlanProductPrice",
  id?: string | null,
  type?: string | null,
  currency?: string | null,
  billingScheme?: string | null,
  unitAmount?: number | null,
  recurring?: PreliminaryPlanRecurringPricing | null,
};

export type PreliminaryPlanRecurringPricing = {
  __typename: "PreliminaryPlanRecurringPricing",
  aggregateUsage?: string | null,
  interval?: string | null,
  intervalCount?: number | null,
  usageType?: string | null,
};

export type PreliminaryPlanServing = {
  __typename: "PreliminaryPlanServing",
  unit?: string | null,
  quantity?: number | null,
};

export type PreliminaryPlanIngredient = {
  __typename: "PreliminaryPlanIngredient",
  id: string,
  slug: string,
  name: string,
  category?: string | null,
  shortDescription?: string | null,
  longDescription?: string | null,
  dose?: PreliminaryPlanDosage | null,
  assessments?:  Array<PreliminaryPlanAssessment | null > | null,
};

export type PreliminaryPlanDosage = {
  __typename: "PreliminaryPlanDosage",
  quantity: number,
  unit: string,
};

export type PreliminaryPlanAssessment = {
  __typename: "PreliminaryPlanAssessment",
  type: string,
  name: string,
  slug?: string | null,
};

export type PreliminaryPlanPreferences = {
  __typename: "PreliminaryPlanPreferences",
  pregnancyStage?: PregnancyStage | null,
  gummyFlavor?: GummyFlavor | null,
};

export type PlanBuildLog = {
  __typename: "PlanBuildLog",
  buildId: string,
  startAt: string,
  endAt?: string | null,
  initBy: string,
  error: boolean,
  errors?: Array< string | null > | null,
  typeformEventPayloadArn?: string | null,
  questionnaireDataPayloadArn?: string | null,
  userDataPayloadArn?: string | null,
  assessmentRecommendationPayloadArn?: string | null,
  ruleRecommendationPayloadArn?: string | null,
  dosageRecommendationPayloadArn?: string | null,
  productRecommendationPayloadArn?: string | null,
};

export enum PreliminaryPlanStatus {
  pending = "pending",
  created = "created",
  failed = "failed",
}


export type ModelPurchasableSubscriptionFilterInput = {
  id?: ModelIDInput | null,
  eloId?: ModelStringInput | null,
  slug?: ModelStringInput | null,
  title?: ModelStringInput | null,
  metaData?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelPurchasableSubscriptionFilterInput | null > | null,
  or?: Array< ModelPurchasableSubscriptionFilterInput | null > | null,
  not?: ModelPurchasableSubscriptionFilterInput | null,
};

export type ModelPurchasableSubscriptionConnection = {
  __typename: "ModelPurchasableSubscriptionConnection",
  items:  Array<PurchasableSubscription | null >,
  nextToken?: string | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelUserFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  email?: ModelStringInput | null,
  eloId?: ModelStringInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  deletedAt?: ModelStringInput | null,
  deleteRequestedAt?: ModelStringInput | null,
  deleteScheduledAfter?: ModelStringInput | null,
  deleteReason?: ModelStringInput | null,
  deleteLog?: ModelStringInput | null,
  and?: Array< ModelUserFilterInput | null > | null,
  or?: Array< ModelUserFilterInput | null > | null,
  not?: ModelUserFilterInput | null,
};

export type ModelUserConnection = {
  __typename: "ModelUserConnection",
  items:  Array<User | null >,
  nextToken?: string | null,
};

export type User = {
  __typename: "User",
  id: string,
  owner?: string | null,
  email?: string | null,
  eloId?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  preferences?: UserPreferences | null,
  paymentMethod?: PaymentMethod | null,
  billingAddress?: Address | null,
  shippingAddress?: Address | null,
  invoices?: ModelInvoiceConnection | null,
  orders?: ModelOrderConnection | null,
  subscriptions?: ModelUserSubscriptionConnection | null,
  createdAt: string,
  updatedAt?: string | null,
  deletedAt?: string | null,
  deleteRequestedAt?: string | null,
  deleteScheduledAfter?: string | null,
  deleteReason?: string | null,
  deleteLog?: string | null,
};

export type PaymentMethod = {
  __typename: "PaymentMethod",
  type?: PaymentMethodType | null,
  title?: string | null,
  last4?: string | null,
  partnerId?: string | null,
  partnerPaymentMethodId?: string | null,
  expirationYear?: string | null,
  expirationMonth?: string | null,
};

export type ModelUserSubscriptionConnection = {
  __typename: "ModelUserSubscriptionConnection",
  items:  Array<UserSubscription | null >,
  nextToken?: string | null,
};

export type ModelUserSubscriptionFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  status?: ModelSubscriptionStatusInput | null,
  title?: ModelStringInput | null,
  cancellationReason?: ModelStringInput | null,
  eloId?: ModelIDInput | null,
  purchasableSubscriptionId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  startAt?: ModelStringInput | null,
  endAt?: ModelStringInput | null,
  periodStartAt?: ModelStringInput | null,
  periodEndAt?: ModelStringInput | null,
  committedUntil?: ModelStringInput | null,
  pauseRequestedAt?: ModelStringInput | null,
  pausedAt?: ModelStringInput | null,
  pausedUntil?: ModelStringInput | null,
  cancellationRequestedAt?: ModelStringInput | null,
  affiliationCode?: ModelStringInput | null,
  referralCode?: ModelStringInput | null,
  and?: Array< ModelUserSubscriptionFilterInput | null > | null,
  or?: Array< ModelUserSubscriptionFilterInput | null > | null,
  not?: ModelUserSubscriptionFilterInput | null,
};

export type ModelSubscriptionStatusInput = {
  eq?: SubscriptionStatus | null,
  ne?: SubscriptionStatus | null,
};

export type GetLineItemsInput = {
  anonymousId?: string | null,
  referralCode?: string | null,
  affiliation?: string | null,
};

export type LineItem = {
  __typename: "LineItem",
  productId?: string | null,
  productSlug?: string | null,
  product?: Product | null,
  purchasableSubscriptionId?: string | null,
  purchasableSubscriptionSlug?: string | null,
  purchasableSubscription?: PurchasableSubscription | null,
  quantity: number,
  priceId: string,
  unitPrice: number,
  totalPrice: number,
  discount?: number | null,
  subTotalPrice?: number | null,
  title: string,
  currency: Currency,
  flavors?: Array< GummyFlavor | null > | null,
  referrerName?: string | null,
  referralCode?: string | null,
  affiliation?: string | null,
};

export type UserConfiguration = {
  __typename: "UserConfiguration",
  allPurchasableSubscriptions:  Array<PurchasableSubscription >,
  purchasableSubscriptions:  Array<PurchasableSubscription >,
  gummyFlavors: Array< GummyFlavor >,
  pregnancyStages: Array< PregnancyStage >,
};

export enum PaymentGateway {
  stripe = "stripe",
}


export type PaymentGatewayCredentials = {
  __typename: "PaymentGatewayCredentials",
  customerId: string,
  ephemeralKeySecret: string,
  setupIntentClientSecret: string,
};

export type ZendeskToken = {
  __typename: "ZendeskToken",
  token: string,
};

export type StartCheckoutMutationVariables = {
  input: StartCheckoutInput,
};

export type StartCheckoutMutation = {
  startCheckout?:  {
    __typename: "CheckoutDetails",
    checkoutUrl?: string | null,
    expiresAt?: string | null,
    orderId?: string | null,
    name?: string | null,
    email?: string | null,
    shippingAddress?:  {
      __typename: "Address",
      name?: string | null,
      company?: string | null,
      street?: string | null,
      street2?: string | null,
      city?: string | null,
      state?: string | null,
      zip?: string | null,
      country?: string | null,
    } | null,
    billingAddress?:  {
      __typename: "Address",
      name?: string | null,
      company?: string | null,
      street?: string | null,
      street2?: string | null,
      city?: string | null,
      state?: string | null,
      zip?: string | null,
      country?: string | null,
    } | null,
    paymentMethodType?: string | null,
    paymentMethodDescription?: string | null,
    lineItems?:  Array< {
      __typename: "TransactionLineItem",
      type: TransactionLineItemType,
      title: string,
      id: string,
      priceId?: string | null,
      sku?: string | null,
      imageUrl?: string | null,
      description?: string | null,
      quantity: number,
      unitPrice: number,
      trialUnitPrice?: number | null,
      currency: Currency,
      referralCode?: string | null,
    } | null > | null,
    totalPrice?:  {
      __typename: "PriceDetails",
      currency?: Currency | null,
      discount?: number | null,
      shipping?: number | null,
      tax?: number | null,
      total?: number | null,
    } | null,
    appStoreDownloadUrl?: string | null,
    fail?:  {
      __typename: "CheckoutStartFailure",
      errorCode?: CheckoutStartError | null,
      errorMessage?: string | null,
    } | null,
  } | null,
};

export type UpdateUserMutationVariables = {
  input: UpdateUserInput,
};

export type UpdateUserMutation = {
  updateUser?:  {
    __typename: "UpdateUserResult",
    firstName?: string | null,
    lastName?: string | null,
    preferences?:  {
      __typename: "UserPreferences",
      pregnancyStage?: PregnancyStage | null,
      gummyFlavor?: GummyFlavor | null,
    } | null,
    shippingAddress?:  {
      __typename: "Address",
      name?: string | null,
      company?: string | null,
      street?: string | null,
      street2?: string | null,
      city?: string | null,
      state?: string | null,
      zip?: string | null,
      country?: string | null,
    } | null,
  } | null,
};

export type CancelSubscriptionMutationVariables = {
  input: CancelSubscriptionInput,
};

export type CancelSubscriptionMutation = {
  cancelSubscription?:  {
    __typename: "CancelSubscriptionResult",
    userSubscription?:  {
      __typename: "UserSubscription",
      id: string,
      owner?: string | null,
      userId?: string | null,
      status: SubscriptionStatus,
      title?: string | null,
      cancellationReason?: string | null,
      eloId?: string | null,
      purchasableSubscriptionId: string,
      createdAt: string,
      updatedAt?: string | null,
      startAt?: string | null,
      endAt?: string | null,
      periodStartAt?: string | null,
      periodEndAt?: string | null,
      committedUntil?: string | null,
      pauseRequestedAt?: string | null,
      pausedAt?: string | null,
      pausedUntil?: string | null,
      cancellationRequestedAt?: string | null,
      affiliationCode?: string | null,
      referralCode?: string | null,
    } | null,
  } | null,
};

export type UpdateSubscriptionMutationVariables = {
  input: UpdateSubscriptionInput,
};

export type UpdateSubscriptionMutation = {
  updateSubscription?:  {
    __typename: "UpdateSubscriptionResult",
    userSubscription?:  {
      __typename: "UserSubscription",
      id: string,
      owner?: string | null,
      userId?: string | null,
      status: SubscriptionStatus,
      title?: string | null,
      cancellationReason?: string | null,
      eloId?: string | null,
      purchasableSubscriptionId: string,
      createdAt: string,
      updatedAt?: string | null,
      startAt?: string | null,
      endAt?: string | null,
      periodStartAt?: string | null,
      periodEndAt?: string | null,
      committedUntil?: string | null,
      pauseRequestedAt?: string | null,
      pausedAt?: string | null,
      pausedUntil?: string | null,
      cancellationRequestedAt?: string | null,
      affiliationCode?: string | null,
      referralCode?: string | null,
    } | null,
  } | null,
};

export type DeleteUserMutationVariables = {
  input: DeleteUserInput,
};

export type DeleteUserMutation = {
  deleteUser?:  {
    __typename: "DeleteUserResult",
    deleteScheduledAfter?: string | null,
  } | null,
};

export type CreateReferralLinkMutationVariables = {
};

export type CreateReferralLinkMutation = {
  createReferralLink:  {
    __typename: "ReferralLinkDetails",
    referralCode: string,
    url: string,
  },
};

export type GetInvoiceQueryVariables = {
  id: string,
};

export type GetInvoiceQuery = {
  getInvoice?:  {
    __typename: "Invoice",
    id: string,
    owner?: string | null,
    userId?: string | null,
    status: InvoiceStatus,
    subscriptionId: string,
    title?: string | null,
    net?: number | null,
    currency?: Currency | null,
    discount?: number | null,
    tax?: number | null,
    subtotal?: number | null,
    total?: number | null,
    amountPaid?: number | null,
    amountRefunded?: number | null,
    paymentError?: string | null,
    createdAt: string,
    updatedAt?: string | null,
    paidAt?: string | null,
    refundedAt?: string | null,
  } | null,
};

export type ListInvoicesQueryVariables = {
  id?: string | null,
  filter?: ModelInvoiceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListInvoicesQuery = {
  listInvoices?:  {
    __typename: "ModelInvoiceConnection",
    items:  Array< {
      __typename: "Invoice",
      id: string,
      owner?: string | null,
      userId?: string | null,
      status: InvoiceStatus,
      subscriptionId: string,
      title?: string | null,
      net?: number | null,
      currency?: Currency | null,
      discount?: number | null,
      tax?: number | null,
      subtotal?: number | null,
      total?: number | null,
      amountPaid?: number | null,
      amountRefunded?: number | null,
      paymentError?: string | null,
      createdAt: string,
      updatedAt?: string | null,
      paidAt?: string | null,
      refundedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetOrderQueryVariables = {
  id: string,
};

export type GetOrderQuery = {
  getOrder?:  {
    __typename: "Order",
    id: string,
    owner?: string | null,
    userId?: string | null,
    status: OrderStatus,
    title?: string | null,
    shippingCarrier?: string | null,
    trackingId?: string | null,
    trackingURL?: string | null,
    trackingEvents?:  Array< {
      __typename: "ShipmentEvent",
      createdAt: string,
      title?: string | null,
      description?: string | null,
    } > | null,
    lineItems?:  Array< {
      __typename: "OrderLineItem",
      productId?: string | null,
      parentProductId?: string | null,
      name?: string | null,
      quantity?: number | null,
    } > | null,
    createdAt: string,
    updatedAt?: string | null,
    submittedAt?: string | null,
    shippedAt?: string | null,
    completedAt?: string | null,
    canceledAt?: string | null,
    failedAt?: string | null,
  } | null,
};

export type ListOrdersQueryVariables = {
  id?: string | null,
  filter?: ModelOrderFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListOrdersQuery = {
  listOrders?:  {
    __typename: "ModelOrderConnection",
    items:  Array< {
      __typename: "Order",
      id: string,
      owner?: string | null,
      userId?: string | null,
      status: OrderStatus,
      title?: string | null,
      shippingCarrier?: string | null,
      trackingId?: string | null,
      trackingURL?: string | null,
      createdAt: string,
      updatedAt?: string | null,
      submittedAt?: string | null,
      shippedAt?: string | null,
      completedAt?: string | null,
      canceledAt?: string | null,
      failedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPreliminaryPlanQueryVariables = {
  id: string,
};

export type GetPreliminaryPlanQuery = {
  getPreliminaryPlan?:  {
    __typename: "PreliminaryPlan",
    id: string,
    createdAt: string,
    updatedAt?: string | null,
    expiresAt?: string | null,
    email: string,
    member:  {
      __typename: "PreliminaryPlanMember",
      anonymousId?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      name?: string | null,
      email?: string | null,
      affiliations?: Array< string | null > | null,
      age?: number | null,
      sex?: string | null,
      diets?: Array< string | null > | null,
      sports?: Array< string | null > | null,
      goals?: Array< string | null > | null,
      medicalConditions?: Array< string | null > | null,
      nutrientDeficiencies?: Array< string | null > | null,
    },
    products?:  Array< {
      __typename: "PreliminaryPlanProduct",
      id?: string | null,
      slug?: string | null,
      name?: string | null,
      category?: string | null,
      features?: Array< string | null > | null,
      shortDescription?: string | null,
      longDescription?: string | null,
      servingCount?: number | null,
    } | null > | null,
    preferences?:  {
      __typename: "PreliminaryPlanPreferences",
      pregnancyStage?: PregnancyStage | null,
      gummyFlavor?: GummyFlavor | null,
    } | null,
    builds?:  Array< {
      __typename: "PlanBuildLog",
      buildId: string,
      startAt: string,
      endAt?: string | null,
      initBy: string,
      error: boolean,
      errors?: Array< string | null > | null,
      typeformEventPayloadArn?: string | null,
      questionnaireDataPayloadArn?: string | null,
      userDataPayloadArn?: string | null,
      assessmentRecommendationPayloadArn?: string | null,
      ruleRecommendationPayloadArn?: string | null,
      dosageRecommendationPayloadArn?: string | null,
      productRecommendationPayloadArn?: string | null,
    } | null > | null,
    affiliationCode?: string | null,
    referralCode?: string | null,
    status: PreliminaryPlanStatus,
    error?: boolean | null,
    errorMessages?: Array< string | null > | null,
  } | null,
};

export type GetPurchasableSubscriptionQueryVariables = {
  id: string,
};

export type GetPurchasableSubscriptionQuery = {
  getPurchasableSubscription?:  {
    __typename: "PurchasableSubscription",
    id: string,
    eloId?: string | null,
    slug: string,
    title: string,
    phases:  Array< {
      __typename: "SubscriptionPhase",
      id: string,
      eloId: string,
      slug: string,
      pregnancyStage?: PregnancyStage | null,
      gender?: Gender | null,
      durationDays?: number | null,
      startAt?: string | null,
      endAt?: string | null,
    } >,
    metaData?: string | null,
    createdAt: string,
    updatedAt?: string | null,
  } | null,
};

export type ListPurchasableSubscriptionsQueryVariables = {
  id?: string | null,
  filter?: ModelPurchasableSubscriptionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListPurchasableSubscriptionsQuery = {
  listPurchasableSubscriptions?:  {
    __typename: "ModelPurchasableSubscriptionConnection",
    items:  Array< {
      __typename: "PurchasableSubscription",
      id: string,
      eloId?: string | null,
      slug: string,
      title: string,
      metaData?: string | null,
      createdAt: string,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UsersByOwnerAndCreatedAtQueryVariables = {
  owner: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UsersByOwnerAndCreatedAtQuery = {
  usersByOwnerAndCreatedAt?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      owner?: string | null,
      email?: string | null,
      eloId?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      createdAt: string,
      updatedAt?: string | null,
      deletedAt?: string | null,
      deleteRequestedAt?: string | null,
      deleteScheduledAfter?: string | null,
      deleteReason?: string | null,
      deleteLog?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserSubscriptionQueryVariables = {
  id: string,
};

export type GetUserSubscriptionQuery = {
  getUserSubscription?:  {
    __typename: "UserSubscription",
    id: string,
    owner?: string | null,
    userId?: string | null,
    status: SubscriptionStatus,
    title?: string | null,
    cancellationReason?: string | null,
    eloId?: string | null,
    purchasableSubscriptionId: string,
    purchasableSubscription?:  {
      __typename: "PurchasableSubscription",
      id: string,
      eloId?: string | null,
      slug: string,
      title: string,
      metaData?: string | null,
      createdAt: string,
      updatedAt?: string | null,
    } | null,
    purchasedSubscription:  {
      __typename: "PurchasableSubscription",
      id: string,
      eloId?: string | null,
      slug: string,
      title: string,
      metaData?: string | null,
      createdAt: string,
      updatedAt?: string | null,
    },
    preferences?:  {
      __typename: "UserSubscriptionPreferences",
      gummyFlavor?: GummyFlavor | null,
    } | null,
    invoices?:  {
      __typename: "ModelInvoiceConnection",
      nextToken?: string | null,
    } | null,
    committedInvoices?:  Array< {
      __typename: "UpcomingInvoice",
      orderId?: string | null,
      status: InvoiceStatus,
      reason: InvoiceReason,
      dueAt: string,
      periodStartAt?: string | null,
      periodEndAt?: string | null,
      title?: string | null,
      currency: Currency,
      shipping?: number | null,
      discount?: number | null,
      tax?: number | null,
      subtotal?: number | null,
      total: number,
    } | null > | null,
    upcomingInvoices?:  Array< {
      __typename: "UpcomingInvoice",
      orderId?: string | null,
      status: InvoiceStatus,
      reason: InvoiceReason,
      dueAt: string,
      periodStartAt?: string | null,
      periodEndAt?: string | null,
      title?: string | null,
      currency: Currency,
      shipping?: number | null,
      discount?: number | null,
      tax?: number | null,
      subtotal?: number | null,
      total: number,
    } | null > | null,
    createdAt: string,
    updatedAt?: string | null,
    startAt?: string | null,
    endAt?: string | null,
    periodStartAt?: string | null,
    periodEndAt?: string | null,
    committedUntil?: string | null,
    pauseRequestedAt?: string | null,
    pausedAt?: string | null,
    pausedUntil?: string | null,
    cancellationRequestedAt?: string | null,
    affiliationCode?: string | null,
    referralCode?: string | null,
  } | null,
};

export type ListUserSubscriptionsQueryVariables = {
  id?: string | null,
  filter?: ModelUserSubscriptionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListUserSubscriptionsQuery = {
  listUserSubscriptions?:  {
    __typename: "ModelUserSubscriptionConnection",
    items:  Array< {
      __typename: "UserSubscription",
      id: string,
      owner?: string | null,
      userId?: string | null,
      status: SubscriptionStatus,
      title?: string | null,
      cancellationReason?: string | null,
      eloId?: string | null,
      purchasableSubscriptionId: string,
      createdAt: string,
      updatedAt?: string | null,
      startAt?: string | null,
      endAt?: string | null,
      periodStartAt?: string | null,
      periodEndAt?: string | null,
      committedUntil?: string | null,
      pauseRequestedAt?: string | null,
      pausedAt?: string | null,
      pausedUntil?: string | null,
      cancellationRequestedAt?: string | null,
      affiliationCode?: string | null,
      referralCode?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetLineItemsQueryVariables = {
  input?: GetLineItemsInput | null,
};

export type GetLineItemsQuery = {
  getLineItems:  Array< {
    __typename: "LineItem",
    productId?: string | null,
    productSlug?: string | null,
    product?:  {
      __typename: "Product",
      id?: string | null,
      slug?: string | null,
      name?: string | null,
      category?: string | null,
      features?: Array< string | null > | null,
      shortDescription?: string | null,
      longDescription?: string | null,
      servingCount?: number | null,
    } | null,
    purchasableSubscriptionId?: string | null,
    purchasableSubscriptionSlug?: string | null,
    purchasableSubscription?:  {
      __typename: "PurchasableSubscription",
      id: string,
      eloId?: string | null,
      slug: string,
      title: string,
      metaData?: string | null,
      createdAt: string,
      updatedAt?: string | null,
    } | null,
    quantity: number,
    priceId: string,
    unitPrice: number,
    totalPrice: number,
    discount?: number | null,
    subTotalPrice?: number | null,
    title: string,
    currency: Currency,
    flavors?: Array< GummyFlavor | null > | null,
    referrerName?: string | null,
    referralCode?: string | null,
    affiliation?: string | null,
  } >,
};

export type GetCheckoutQueryVariables = {
  sessionId: string,
};

export type GetCheckoutQuery = {
  getCheckout?:  {
    __typename: "CheckoutDetails",
    checkoutUrl?: string | null,
    expiresAt?: string | null,
    orderId?: string | null,
    name?: string | null,
    email?: string | null,
    shippingAddress?:  {
      __typename: "Address",
      name?: string | null,
      company?: string | null,
      street?: string | null,
      street2?: string | null,
      city?: string | null,
      state?: string | null,
      zip?: string | null,
      country?: string | null,
    } | null,
    billingAddress?:  {
      __typename: "Address",
      name?: string | null,
      company?: string | null,
      street?: string | null,
      street2?: string | null,
      city?: string | null,
      state?: string | null,
      zip?: string | null,
      country?: string | null,
    } | null,
    paymentMethodType?: string | null,
    paymentMethodDescription?: string | null,
    lineItems?:  Array< {
      __typename: "TransactionLineItem",
      type: TransactionLineItemType,
      title: string,
      id: string,
      priceId?: string | null,
      sku?: string | null,
      imageUrl?: string | null,
      description?: string | null,
      quantity: number,
      unitPrice: number,
      trialUnitPrice?: number | null,
      currency: Currency,
      referralCode?: string | null,
    } | null > | null,
    totalPrice?:  {
      __typename: "PriceDetails",
      currency?: Currency | null,
      discount?: number | null,
      shipping?: number | null,
      tax?: number | null,
      total?: number | null,
    } | null,
    appStoreDownloadUrl?: string | null,
    fail?:  {
      __typename: "CheckoutStartFailure",
      errorCode?: CheckoutStartError | null,
      errorMessage?: string | null,
    } | null,
  } | null,
};

export type MeQueryVariables = {
};

export type MeQuery = {
  me:  {
    __typename: "User",
    id: string,
    owner?: string | null,
    email?: string | null,
    eloId?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    preferences?:  {
      __typename: "UserPreferences",
      pregnancyStage?: PregnancyStage | null,
      gummyFlavor?: GummyFlavor | null,
    } | null,
    paymentMethod?:  {
      __typename: "PaymentMethod",
      type?: PaymentMethodType | null,
      title?: string | null,
      last4?: string | null,
      partnerId?: string | null,
      partnerPaymentMethodId?: string | null,
      expirationYear?: string | null,
      expirationMonth?: string | null,
    } | null,
    billingAddress?:  {
      __typename: "Address",
      name?: string | null,
      company?: string | null,
      street?: string | null,
      street2?: string | null,
      city?: string | null,
      state?: string | null,
      zip?: string | null,
      country?: string | null,
    } | null,
    shippingAddress?:  {
      __typename: "Address",
      name?: string | null,
      company?: string | null,
      street?: string | null,
      street2?: string | null,
      city?: string | null,
      state?: string | null,
      zip?: string | null,
      country?: string | null,
    } | null,
    invoices?:  {
      __typename: "ModelInvoiceConnection",
      nextToken?: string | null,
    } | null,
    orders?:  {
      __typename: "ModelOrderConnection",
      nextToken?: string | null,
    } | null,
    subscriptions?:  {
      __typename: "ModelUserSubscriptionConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt?: string | null,
    deletedAt?: string | null,
    deleteRequestedAt?: string | null,
    deleteScheduledAfter?: string | null,
    deleteReason?: string | null,
    deleteLog?: string | null,
  },
};

export type UserConfigurationQueryVariables = {
};

export type UserConfigurationQuery = {
  userConfiguration:  {
    __typename: "UserConfiguration",
    allPurchasableSubscriptions:  Array< {
      __typename: "PurchasableSubscription",
      id: string,
      eloId?: string | null,
      slug: string,
      title: string,
      metaData?: string | null,
      createdAt: string,
      updatedAt?: string | null,
    } >,
    purchasableSubscriptions:  Array< {
      __typename: "PurchasableSubscription",
      id: string,
      eloId?: string | null,
      slug: string,
      title: string,
      metaData?: string | null,
      createdAt: string,
      updatedAt?: string | null,
    } >,
    gummyFlavors: Array< GummyFlavor >,
    pregnancyStages: Array< PregnancyStage >,
  },
};

export type PaymentGatewayCredentialsQueryVariables = {
  paymentGateway: PaymentGateway,
};

export type PaymentGatewayCredentialsQuery = {
  paymentGatewayCredentials:  {
    __typename: "PaymentGatewayCredentials",
    customerId: string,
    ephemeralKeySecret: string,
    setupIntentClientSecret: string,
  },
};

export type ZendeskTokenQueryVariables = {
};

export type ZendeskTokenQuery = {
  zendeskToken:  {
    __typename: "ZendeskToken",
    token: string,
  },
};
