/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const startCheckout = /* GraphQL */ `mutation StartCheckout($input: StartCheckoutInput!) {
  startCheckout(input: $input) {
    checkoutUrl
    expiresAt
    orderId
    name
    email
    shippingAddress {
      name
      company
      street
      street2
      city
      state
      zip
      country
      __typename
    }
    billingAddress {
      name
      company
      street
      street2
      city
      state
      zip
      country
      __typename
    }
    paymentMethodType
    paymentMethodDescription
    lineItems {
      type
      title
      id
      priceId
      sku
      imageUrl
      description
      quantity
      unitPrice
      trialUnitPrice
      currency
      referralCode
      __typename
    }
    totalPrice {
      currency
      discount
      shipping
      tax
      total
      __typename
    }
    appStoreDownloadUrl
    fail {
      errorCode
      errorMessage
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.StartCheckoutMutationVariables,
  APITypes.StartCheckoutMutation
>;
export const updateUser = /* GraphQL */ `mutation UpdateUser($input: UpdateUserInput!) {
  updateUser(input: $input) {
    firstName
    lastName
    preferences {
      pregnancyStage
      gummyFlavor
      __typename
    }
    shippingAddress {
      name
      company
      street
      street2
      city
      state
      zip
      country
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserMutationVariables,
  APITypes.UpdateUserMutation
>;
export const cancelSubscription = /* GraphQL */ `mutation CancelSubscription($input: CancelSubscriptionInput!) {
  cancelSubscription(input: $input) {
    userSubscription {
      id
      owner
      userId
      status
      title
      cancellationReason
      eloId
      purchasableSubscriptionId
      createdAt
      updatedAt
      startAt
      endAt
      periodStartAt
      periodEndAt
      committedUntil
      pauseRequestedAt
      pausedAt
      pausedUntil
      cancellationRequestedAt
      affiliationCode
      referralCode
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CancelSubscriptionMutationVariables,
  APITypes.CancelSubscriptionMutation
>;
export const updateSubscription = /* GraphQL */ `mutation UpdateSubscription($input: UpdateSubscriptionInput!) {
  updateSubscription(input: $input) {
    userSubscription {
      id
      owner
      userId
      status
      title
      cancellationReason
      eloId
      purchasableSubscriptionId
      createdAt
      updatedAt
      startAt
      endAt
      periodStartAt
      periodEndAt
      committedUntil
      pauseRequestedAt
      pausedAt
      pausedUntil
      cancellationRequestedAt
      affiliationCode
      referralCode
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSubscriptionMutationVariables,
  APITypes.UpdateSubscriptionMutation
>;
export const deleteUser = /* GraphQL */ `mutation DeleteUser($input: DeleteUserInput!) {
  deleteUser(input: $input) {
    deleteScheduledAfter
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserMutationVariables,
  APITypes.DeleteUserMutation
>;
export const createReferralLink = /* GraphQL */ `mutation CreateReferralLink {
  createReferralLink {
    referralCode
    url
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateReferralLinkMutationVariables,
  APITypes.CreateReferralLinkMutation
>;
